import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './AccountList.css'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import LimitOnProfile from './../Public/Image/Join/LimitOnProfile.svg'
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';
import { useEffect } from 'react'
import { TelegramDB } from './../DataBase/TelegramDB'
import { useState } from 'react'
import Loading from './../Loading'
// import { AutoJoinClass } from './AutoJoinClass'
import { SetCountJoining } from './../app/JoinState'
import { BottomSheet } from 'react-spring-bottom-sheet'
import ReactCountryFlag from "react-country-flag"
import Snackbar from '@mui/material/Snackbar';
import { setMiniLoading } from './../app/Home'
import { setUserCoin } from './../app/Home'
import WifiOffIcon from '@mui/icons-material/WifiOff';
// import { JoinChannel } from './../CoinApi/JoinChannel'

import { GetCookie } from './../Action/GetCookie'
// import { GetChannels } from './../CoinApi/GetChannels'
import { CheckConnection } from './CheckConnection'
import { SetAccountIsFilled } from './../app/JoinState'
import { SetTooMuchLeft } from './../app/JoinState'
import { setAutoJoinLocal } from './../app/JoinState'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import { SetJoinCountRedux } from './../app/Game'

import GameCoin from './GameCoin/GameCoin'
import AddAccount2 from './../Public/Image/Join/AddAccount2.svg'
import AutoJoinList from './../Public/Image/Join/AutoJoinList.svg'

import plus15 from './../Public/Image/Join/+15.svg'
import JoinStop from './../Public/Image/Join/JoinStop.svg'
const AccountList = ({ TelegramAccount }) => {



    const history = useHistory();

    let Token = GetCookie('Token');
    const dispatch = useDispatch()
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const AccountIsFilled = useSelector((state) => state.JoinState.AccountIsFilled)
    const TooMuchLeft = useSelector((state) => state.JoinState.TooMuchLeft)
    const [LoadingState, setLoadingState] = useState(false)
    const [ShowLogOut, setShowLogOut] = useState(false)
    const [SnackbarLogout, setSnackbarLogout] = useState(false);
    const [PhoneForLogout, setPhoneForLogout] = useState('')
    const [IdForLogout, setIdForLogout] = useState('')
    const [LoadingForLogOut, setLoadingForLogOut] = useState(false)
    const [AccountCount, setAccountCount] = useState(0)
    const [FloodWaitState, setFloodWaitState] = useState(false)
    const [ShowStartAll, setShowStartAll] = useState(true)
    // const [TelegramAccount, setTelegramAccount] = useState('')
    const AutoJoinLocal = useSelector((state) => state.JoinState.AutoJoinLocal)




    useEffect(() => {
        // CountActiveAcounts()

        CheckAccounts()

        setCountJoiningLoading(true)
        setTimeout(() => {
            setCountJoiningLoading(false)
        }, 2000);
        CheckAccounts()
        // console.log('Useeffect')
        // }, [CountJoining])
    }, [])

    const GetUpdatedTelegramAccont = async () => {
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        // setTelegramAccount(TelegramAccountUpdate)
        return TelegramAccountUpdate
    }
    const [CountJoiningLoading, setCountJoiningLoading] = useState(false)

    useEffect(() => {

        // console.log("waiter")
        GetUpdatedTelegramAccont()
        let UpdatedAccount
        const Waiter = setInterval(() => {

            if (UpdatedAccount) {
                clearInterval(Waiter)
            } else {
                (async function () {
                    let res = await GetUpdatedTelegramAccont()
                    // console.log(res[0].Session)
                    UpdatedAccount = res
                    if (UpdatedAccount) {
                        CountBannedLogOut()
                        CheckAccounts()

                        // let AccountAutoJoinIsTrue = []
                        UpdatedAccount.map(item => {

                            if (item.Session != false) {
                                // console.log('checklimit')
                                // console.log(item) 


                                CheckStatus(item)
                                // if (Status == false) {
                                if (item.AutoJoinSingle == true) {
                                    // AccountAutoJoinIsTrue.push(item)
                                    if (AutoJoinStatus == false) {
                                        // let JoiningAccount = []

                                        // if (JoiningAccount.length > 0) {
                                        //     setAutoJoinStatus(true)
                                        // } else {
                                        // if (item.AutoJoin == true) {
                                        // console.log('set single useeffect')
                                        StartOneAccountJoin(item)
                                        // }

                                    }
                                } else if (item.AutoJoin == true) {
                                    // console.log('set list')
                                    // if (AutoJoinStatus == false) {
                                    // console.log('set single1')
                                    CheckFloodWait(item)

                                    // StartOneAccountJoin(item)
                                    // }
                                } else {
                                    // console.log('check flood')
                                    CheckFloodWait(item)
                                }
                                // }
                            }
                        })


                    }
                })();


            }
        }, 1000);



    }, [])
    const CheckAccounts = async () => {

        let ActiveAccount = []
        let arr = []
        let RestrictAccount = []
        let res = await GetUpdatedTelegramAccont()
        res.map(item => {
            if (item.Session != false && item.BotSession != "") {
                ActiveAccount.push(item)
            }



            // else {
            if (item.AutoJoin == true && item.Session != false && item.BotSession != false) {

                setAutoJoinStatus(true)
                if (item.FloodWait > 0 || item.CHANNELS_TOO_MUCH == true || item.TooMuchLeft == true) {
                    RestrictAccount.push(item.id)
                } else {
                    arr.push(item.id)
                }
            }
            // }
        })
        // console.log(arr)
        setAccountCount(ActiveAccount.length)
        dispatch(SetCountJoining(arr.length))
        if (RestrictAccount.length == res.length) {
            setShowStartAll(false)
        } else {
            setShowStartAll(true)
        }




        ActiveAccount.map(item => {
            item.ListForJoin = []
            // console.log(item.ListForJoin)
        })
        // console.log(ActiveAccount)
        const MakeApiClass = new AllApi();
        let SessionsAccountRes = await MakeApiClass.SessionsAccountPut(ActiveAccount)
        // console.log(SessionsAccountRes) 

    }

    const [AutoJoinStatus, setAutoJoinStatus] = useState(false)
    // console.log(AutoJoinStatus,CountJoining)
    async function SetAutoJoinDB(ID) {
        try {
            const id = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: true });
        } catch (error) {
            // console.log(`Failed to add ${ID}: ${error}`);
        }
    }

    const AutoManualJoinObject = {

        async connectWithTimeout() {
            const stringSession = new StringSession(this.MySession);
            const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
                connectionRetries: 1,
            });
            const connectPromise = Client.connect();
            const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    reject(('Connection timed out after 5 seconds'));
                }, 5000);
            });

            return Promise.race([connectPromise, timeoutPromise, Client]);
        },
        async checkConnection() {
            try {
                const connectRes = await this.connectWithTimeout(this.MySession, this.ID);
                // console.log('Connection successful:', connectRes);
                return { status: true, Client: connectRes }
            } catch (error) {
                // console.log('Connection failed:', error);

                return { status: false }
            }
        },
        async Join(Client, List) {
            // console.log(Client, List)
            let ResConnection1
            let JoinTimeout;
            const JoinPromise1 = new Promise((resolve, reject) => {
                // setTimeout(resolve, 2, CheckConnection(this.MySession, this.ID));
                resolve(CheckConnection(this.MySession, this.ID));
                clearTimeout(JoinTimeout)
            });


            const JoinPromise2 = new Promise((resolve, reject) => {
                JoinTimeout = setTimeout(resolve, 15000, 'errorConnection');
            });
            // ResConnection1 = await CheckConnection(this.MySession, this.ID)
            ResConnection1 = await Promise.race([JoinPromise1, JoinPromise2])
            // console.log(ResConnection1)






            let JoinRes
            if (ResConnection1.status == true || ResConnection1.status == undefined && ResConnection1.Client != false) {
                try {
                    // const result = await Client.invoke(
                    //     new Api.channels.JoinChannel({
                    //         channel: List[0].username,
                    //     })
                    // );
                    // JoinRes = result;
                    let ConTimeout;

                    const myPromise1 = new Promise((resolve, reject) => {
                        resolve(Client.invoke(
                            new Api.channels.JoinChannel({
                                channel: List[0].username,
                            })
                        ));
                        clearTimeout(ConTimeout)
                    });


                    const myPromise2 = new Promise((resolve, reject) => {
                        // ConTimeout = setTimeout(resolve, 50000, 'errorCheck')
                        ConTimeout = setTimeout(resolve, 20000, false)
                    });
                    JoinRes = await Promise.race([myPromise1, myPromise2])
                } catch (error) {
                    if (error.errorMessage == 'FLOOD') {
                        // console.log(error.errorMessage)
                        // console.log(error.stack)
                        // console.log(error.seconds)
                        let LimitTime = error.seconds * 1000
                        let FinalLimit = Date.now() + LimitTime
                        // console.log(ID)
                        try {
                            let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ FloodWait: FinalLimit })
                        } catch (error) {
                            // console.log(error)
                        }
                        // try {
                        //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: false })
                        // } catch (error) {
                        //     console.log(error)
                        // }
                        // Status = { Repeat: true, Status: res.errorMessage }
                        JoinRes = 'Flood'
                    } else {
                        JoinRes = error
                    }

                }
            } else {
                JoinRes = 'errorConnection'


            }
            // console.log(JoinRes)

            return JoinRes
        },
        async IsMember(Client, List, ID, User) {
            // let ResConnection1 = await CheckConnection(this.MySession, this.ID)
            let JoinTimeout;
            // let ResConnection1
            // const JoinPromise1 = new Promise((resolve, reject) => {
            //     resolve(CheckConnection(this.MySession, this.ID));
            //     clearTimeout(JoinTimeout)
            // });


            // const JoinPromise2 = new Promise((resolve, reject) => {
            //     JoinTimeout = setTimeout(resolve, 20000, 'errorConnection');
            // });
            // ResConnection1 = await Promise.race([JoinPromise1, JoinPromise2])

            let IsMemberUser;
            // console.log(ResConnection1)
            // if (ResConnection1.status == true && ResConnection1.Client != false) {

            // console.log(List)
            try {
                // console.log("username " + this.Username)
                // console.log("user id " + this.ID)
                // let timer1


                // if (this.Username === null) {
                // IsMemberUser = 'username null'
                // console.log(IsMemberUser)
                let ConTimeout;

                const myPromise1 = new Promise((resolve, reject) => {
                    resolve(Client.invoke(
                        new Api.channels.GetParticipant({
                            channel: List[0].username,
                            participant: User.id.toString(),
                        })
                    ));
                    clearTimeout(ConTimeout)
                });


                const myPromise2 = new Promise((resolve, reject) => {
                    // ConTimeout = setTimeout(resolve, 50000, 'errorCheck')
                    ConTimeout = setTimeout(resolve, 20000, false)
                });
                IsMemberUser = await Promise.race([myPromise1, myPromise2])
                // const IsMember = await Client.invoke(
                //     new Api.channels.GetParticipant({
                //         channel: List[0].username,
                //         participant: User.id.toString(),
                //     })
                // );

                // IsMemberUser = true
                // console.log(238)
                // console.log(IsMemberUser)
                // } else {
                //     console.log(IsMemberUser)
                //     const IsMember = await Client.invoke(
                //         new Api.channels.GetParticipant({
                //             channel: List[0].username,
                //             participant: this.Username,
                //         })
                //     );
                //     IsMemberUser = true
                //     console.log(247)

                // }

            } catch (error) {
                console.log(error)
                if (error.errorMessage == "USER_NOT_PARTICIPANT") {
                    IsMemberUser = false
                } else if (error.errorMessage == 'FLOOD') {
                    // console.log(error.errorMessage)
                    // console.log(error.stack)
                    // console.log(error.seconds)
                    let LimitTime = error.seconds * 1000
                    let FinalLimit = Date.now() + LimitTime
                    // console.log(this.ID)
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ FloodWait: FinalLimit })
                    } catch (error) {
                        // console.log(error)
                    }
                    // try {
                    //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: false })
                    // } catch (error) {
                    //     console.log(error)
                    // }
                    // Status = { Repeat: true, Status: res.errorMessage }
                    IsMemberUser = 'Flood'
                } else if (error.errorMessage == 'AUTH_KEY_UNREGISTERED' || error.errorMessage == "USER_DEACTIVATED_BAN" || error.errorMessage == "AUTH_KEY_DUPLICATED" || error.errorMessage == "AUTH_KEY_UNREGISTERED" || error.errorMessage == "SESSION_REVOKED") {
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ Session: false })
                        let re1s = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: false })
                        // console.log(res)
                        // history.push(`/Coin`);
                        CountBannedLogOut()

                    } catch (error) {
                        // console.log(error)

                    }
                    CountBannedLogOut()
                    IsMemberUser = 'error'

                } else {
                    IsMemberUser = 'error'

                }
                // console.log(error.errorMessage)


            }
            // } else {
            //     // handleClick({ vertical: 'bottom', horizontal: 'center' })
            //     console.log(ResConnection1)
            //     IsMemberUser = 'errorConnection'


            // }
            // console.log(IsMemberUser)

            return IsMemberUser

        },
        async Next(List) {
            // console.log(List)
            let NewList = List.filter((item, i) => {
                if (i != 0) {
                    return item
                }
            })
            // console.log(NewList)
            try {
                let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ ListForJoin: NewList })
            } catch (error) {
                console.log(error)

            }
        },
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async demo(time) {
            let status = false;
            for (let i = 0; i <= time; i++) {
                let TelegramAccountUpdate = await TelegramDB.Data.toArray()
                let Account
                TelegramAccountUpdate.map(item => {
                    if (item.id == this.ID) {
                        Account = item
                    }
                })

                if (Account.AutoJoin == true) {
                    await this.sleep(1000);
                    // console.log(`Waiting ${i} seconds...`);
                    status = true
                } else {
                    status = false
                    break
                }

            }
            return status
        },
        async Loop(ID, AccountsLength) {
            // console.log(ID)
            let TelegramAccountUpdate = await TelegramDB.Data.toArray()
            let Account
            let Status = null
            TelegramAccountUpdate.map(item => {
                if (item.id == ID) {
                    Account = item
                }
            })
            // console.log(Account)
            try {
                if (Account != undefined) {
                    if (Account.ListForJoin) {

                        if (Account.ListForJoin.length > 0) {

                            // let ResConnection1 =await CheckConnection(Account.Session, Account.id)
                            let ConTimeout;
                            let ResConnection1
                            const myPromise1 = new Promise((resolve, reject) => {
                                resolve(CheckConnection(Account.Session, Account.id));
                                clearTimeout(ConTimeout)
                            });


                            const myPromise2 = new Promise((resolve, reject) => {
                                ConTimeout = setTimeout(resolve, 14118000, 'stop')
                            });


                            ResConnection1 = await Promise.race([myPromise1, myPromise2]).then((x) => {
                                return x
                            });

                            // console.log(ResConnection1)


                            if (ResConnection1.status == true || ResConnection1.status == undefined && ResConnection1.Client != false && ResConnection1.Client != undefined && ResConnection1.Client != "undefined") {
                                // Connection Ok
                                if (Account.FloodWait == 0 || Account.FloodWait == undefined) {
                                    // Flood Wait false
                                    const MyClient = ResConnection1.Client






                                    if (Account.AutoJoin == true) {
                                        // Auto Join True
                                        // await MyClient.connect()
                                        let IsMember = await this.IsMember(MyClient, Account.ListForJoin, ID, Account.User);
                                        // console.log('auto join true ')
                                        // console.log(IsMember)
                                        CountBannedLogOut()
                                        MyClient.destroy();

                                        // let speed = 5
                                        let speed = 1

                                        if (Account.SpeedJoin) {
                                            if (Account.SpeedJoin.Status == 'Slowly') {
                                                // let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                                                let random = 0
                                                speed = random + 2
                                            } else if (Account.SpeedJoin.Status == 'Medium') {
                                                // let random = Math.floor(Math.random() * (2 - 0 + 1) + 0)
                                                let random = 0
                                                speed = random + 1
                                            } else if (Account.SpeedJoin.Status == 'Quick') {
                                                // let random = Math.floor(Math.random() * (1 - 0 + 1) + 0)
                                                let random = 0
                                                speed = random + 0
                                            } else if (Account.SpeedJoin.Status == 'Desired') {
                                                speed = Account.SpeedJoin.SpeedOfJoin
                                            }
                                        } else {
                                            let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                                            speed = random + 1
                                        }
                                        // console.log(speed)
                                        if (IsMember === false) {

                                            let DelayBeforeJoin = await this.demo(speed);
                                            if (DelayBeforeJoin == true) {
                                                let TgChID = Account.ListForJoin[0].tgChannelId
                                                let UserName = Account.ListForJoin[0].username
                                                let BotSeS = Account.BotSession
                                                let Id = Account.ListForJoin[0].Id
                                                let _id = Account.ListForJoin[0]._id

                                                let ConTimeout;
                                                let ResConnection1
                                                const myPromise1 = new Promise((resolve, reject) => {
                                                    resolve(CheckConnection(Account.Session, Account.id));
                                                    clearTimeout(ConTimeout)
                                                });


                                                const myPromise2 = new Promise((resolve, reject) => {
                                                    ConTimeout = setTimeout(resolve, 10000, 'stop')
                                                });


                                                ResConnection1 = await Promise.race([myPromise1, myPromise2]).then((x) => {
                                                    return x
                                                });
                                                // console.log(ResConnection1)
                                                const MyClient = ResConnection1.Client
                                                // let resconnectionj = await MyClient.connect()
                                                // console.log(resconnectionj)
                                                let res = await this.Join(MyClient, Account.ListForJoin)


                                                // console.log(res)
                                                // console.log(Account.id)
                                                CountBannedLogOut()
                                                MyClient.destroy();
                                                // console.log(Account)
                                                // CheckLimit(Account)

                                                // res = { errorMessage: 'FLOOD', seconds: 40 }
                                                if (res.chats) {
                                                    const MakeApiClass = new AllApi();
                                                    // console.log(TgChID, UserName, Token, BotSeS, Id, _id)
                                                    let JoinResponse = await MakeApiClass.JoinChannel(TgChID, UserName, Token, BotSeS, Id, _id)
                                                    // let JoinResponse = await JoinChannel(TgChID, UserName, Token, BotSeS, Id, _id)
                                                    console.log(JoinResponse)
                                                    // SetJoinCountRedux(join)
                                                    if (JoinResponse.code == 309) {
                                                        // console.log('error 309')
                                                        await this.Next(Account.ListForJoin)
                                                        Status = { Repeat: true, Status: 'error 309' }

                                                    } else if (JoinResponse.code == 200) {
                                                        // dispatch(SetAccountIsFilled({ status: false, id: ID }))
                                                        dispatch(SetJoinCountRedux(JoinResponse.data.user.joinCount))
                                                        // console.log(JoinResponse)
                                                        let Next = await this.Next(Account.ListForJoin)
                                                        // console.log(Account.AutoJoin)
                                                        if (Account.AutoJoin == true) {
                                                            dispatch(setUserCoin(JoinResponse.data.user.coins))
                                                            let LiveCoinVar = localStorage.getItem(Account.id + "LiveCoin")
                                                            // console.log(LiveCoinVar)
                                                            if (LiveCoinVar == 0 || LiveCoinVar == '0') {
                                                                localStorage.setItem(Account.id + "LiveCoin", 2)
                                                            } else {
                                                                localStorage.setItem(Account.id + "LiveCoin", Number(LiveCoinVar) + 2)
                                                            }
                                                            try {
                                                                let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: false })
                                                            } catch (error) {
                                                                // console.log(error)
                                                            }
                                                            try {
                                                                TelegramDB.Data.where({ id: Number(Account.id) }).modify({ TooMuchLeft: false })
                                                            } catch (error) {
                                                                // console.log(`Failed to add ${'Session'}: ${error}`);
                                                            }
                                                            // dispatch(SetAccountIsFilled({ status: true, id: ID }))
                                                            Status = { Repeat: true, status: 'ok', Coins: JoinResponse.data.user.coins }

                                                        }
                                                    } else if (JoinResponse.code == 120 || JoinResponse.code == 121 || JoinResponse.code == 122 || JoinResponse.code == 123) {
                                                        let name = "Token"
                                                        let name1 = "active"
                                                        document.cookie = name + '=; Max-Age=-99999999;';
                                                        document.cookie = name1 + '=; Max-Age=-99999999;';
                                                        history.push("/");
                                                        // dispatch(setLoading(false))

                                                    } else {
                                                        Status = { Repeat: true, status: 'join response not ok', Coins: null }

                                                    }
                                                } else if (res.errorMessage == 'FLOOD') {
                                                    MyClient.destroy();

                                                    // let LimitTime = res.seconds * 1000
                                                    // let FinalLimit = Date.now() + LimitTime
                                                    // console.log(ID)
                                                    // try {
                                                    //     let res = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ FloodWait: FinalLimit })
                                                    // } catch (error) {
                                                    //     console.log(error)
                                                    // }

                                                    CheckAccounts()
                                                    CheckFloodWait(Account)
                                                    Status = { Repeat: false, Status: res.errorMessage }
                                                } else if (res.errorMessage == "CHANNELS_TOO_MUCH") {
                                                    // dispatch(SetAccountIsFilled({ status: true, id: ID }))
                                                    try {
                                                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: true })
                                                    } catch (error) {
                                                        // console.log(error)
                                                    }
                                                    try {
                                                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: true })
                                                    } catch (error) {
                                                        // console.log(error)
                                                    }
                                                    CheckAccounts()

                                                    Status = { Repeat: true, Status: 'CHANNELS_TOO_MUCH' }
                                                } else if (res == "errorConnection") {
                                                    // console.log(res)
                                                    Status = { Repeat: true, Status: res }
                                                    MyClient.destroy();
                                                } else if (res.errorMessage == 'AUTH_KEY_UNREGISTERED' || res.errorMessage == "USER_DEACTIVATED_BAN" || res.errorMessage == "AUTH_KEY_DUPLICATED" || res.errorMessage == "AUTH_KEY_UNREGISTERED") {
                                                    // const id = await TelegramDB.Data.delete(ItemID);
                                                    let res = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ Session: false })
                                                    let re1s = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ AutoJoin: false })
                                                    Status = { Repeat: false, Status: "account logout" }
                                                    // CheckLimit(Account)
                                                }
                                                else {
                                                    // console.log(res)
                                                    Status = { Repeat: true, Status: res }
                                                }



                                            }
                                        } else if (IsMember == "Flood") {
                                            MyClient.destroy();
                                            CheckAccounts()
                                            CheckFloodWait(Account)
                                            Status = { Repeat: true, Status: "Flood" }
                                        } else if (IsMember == "errorConnection") {
                                            // console.log(IsMember)
                                            Status = { Repeat: true, Status: 'errorConnection' }
                                            try {
                                                // console.log('disconnect')
                                                await MyClient.disconnect();
                                            } catch (error) {
                                                // console.log(error)
                                            }
                                            try {
                                                // console.log('destroy')

                                                await MyClient.destroy();

                                            } catch (error) {
                                                // console.log(error)

                                            }


                                        } else if (IsMember == "errorCheck") {
                                            await MyClient.destroy();
                                            // try {

                                            handleClick({ vertical: 'bottom', horizontal: 'center' })

                                            //     let LimitTime = 60 * 1000
                                            //     let FinalLimit = Date.now() + LimitTime
                                            //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                                            // } catch (error) {
                                            //     console.log(error)
                                            // }
                                            Status = { Repeat: true, Status: 'errorConnection' }
                                        }

                                        else {
                                            // console.log(IsMember)
                                            // if (IsMember == "errorConnection") {
                                            //     console.log(IsMember)
                                            //     Status = { Repeat: true, Status: 'errorConnection' }
                                            //     //  MyClient.disconnect();/
                                            //   MyClient.destroy();


                                            // }
                                            let DelayBeforeJoin = await this.demo(1);
                                            if (DelayBeforeJoin) {
                                                // console.log('Next')
                                                await this.Next(Account.ListForJoin)
                                                Status = { Repeat: true, Status: 'is member true' }
                                            }

                                        }
                                    } else {
                                        // console.log(Account)
                                    }
                                } else {
                                    // Has FloodWait
                                    // console.log('Accountlimit')
                                    ResConnection1.Client.destroy();

                                    // let Accountlimit;
                                    // let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
                                    // TelegramAccountUpdatelimit.map(item => {
                                    //     if (item.id === Number(ID)) {
                                    //         console.log(item)
                                    //         Accountlimit = item
                                    //     }
                                    //     console.log(item)
                                    // })
                                    // CheckLimit(Accountlimit)
                                    CheckFloodWait(Account)
                                    Status = { Repeat: false, Status: 'Accountlimit' }
                                }
                            } else {
                                // Connection Failed
                                if (ResConnection1.status == "logout") {
                                    Status = { Repeat: false, Status: 'logout' }

                                } else {
                                    // setTimeout(() => {
                                    handleClick({ vertical: 'bottom', horizontal: 'center' })
                                    // }, 1000);
                                    let MyItem = await TelegramDB.Data.toArray()

                                    // console.log(ResConnection1)
                                    // console.log('Connection error Loop after 2000 ms')
                                    MyItem.map(item => {
                                        if (item.id == Account.id) {
                                            if (item.AutoJoin == true) {
                                                Status = { Repeat: true, Status: 'connection' }
                                            }

                                        }
                                    })
                                    // ResConnection1=''
                                }


                            }
                        } else {
                            // Get List (Empty)
                            let res = await GetListFromApi(Account.BotSession, Account.id, Account.Session)
                            // console.log(res)
                            if (res == true) {
                                Status = { Repeat: true, Status: 'GetListFromApi' }

                            }
                            else if (res == false) {
                                Status = { Repeat: false, Status: 'GetListFromApi' }
                                try {
                                    await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ AutoJoin: false })
                                } catch (error) {
                                    // console.log(`Failed to add ${'Session'}: ${error}`);
                                }
                            } else {
                                // console.log(1314)
                            }
                        }
                    } else {
                        // Get List (undefined)
                        let res = await GetListFromApi(Account.BotSession, Account.id, Account.Session)
                        // console.log(res)
                        if (res == true) {
                            Status = { Repeat: true, Status: 'GetListFromApi' }

                        } else if (res == false) {
                            Status = { Repeat: false, Status: 'GetListFromApi' }
                            try {
                                await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ AutoJoin: false })
                            } catch (error) {
                                // console.log(`Failed to add ${'Session'}: ${error}`);
                            }
                        } else {
                            // console.log(1330)
                        }

                    }
                } else {
                    Status = { Repeat: false, Status: 'Account not found' }

                }

            } catch (error) {
                console.log(error)
                if (error.errorMessage == "CHANNELS_TOO_MUCH") {
                    dispatch(SetAccountIsFilled({ status: true, id: ID }))
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: true })
                    } catch (error) {
                        // console.log(error)
                    }
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoin: true })
                    } catch (error) {
                        // console.log(error)
                    }
                    CheckAccounts()

                    Status = { Repeat: true, Status: 'CHANNELS_TOO_MUCH' }
                } else {
                    Status = { Repeat: true, Status: error.errorMessage }

                }
            }

            // End Loop And Check

            CountBannedLogOut()

            // console.log(Status)

            // console.log(ID)

            if (Status != null && Status.Repeat == true) {
                if (Status.Status == 'connection' || Status.Status == 'errorConnection') {
                    setTimeout(() => {
                        this.Loop(ID)

                    }, 5000);
                } else {
                    this.Loop(ID)

                }

                // this.Loop(ID)


            }
        },
    }
    const StartOneAccountJoin = async (AccountAutoJoinIsTrue) => {

        setAutoJoinStatus(true)
        // console.log('AutoJoinLocal')
        // dispatch(setAutoJoinLocal({ id: AccountAutoJoinIsTrue.id, Autojoin: false }))

        try {
            let res = await TelegramDB.Data.where({ id: Number(AccountAutoJoinIsTrue.id) }).modify({ AutoJoinSingle: false })
        } catch (error) {
            console.log(error)
        }
        CheckAccounts()
        let Session = AccountAutoJoinIsTrue.Session
        let ListForJoin = AccountAutoJoinIsTrue.ListForJoin
        let UserName = AccountAutoJoinIsTrue.User.username
        let ID = AccountAutoJoinIsTrue.id
        // const MyAccount = new AutoManualJoin(Session, ListForJoin, UserName, ID);
        const MyAccount = Object.create(AutoManualJoinObject);
        MyAccount.Session = Session
        MyAccount.ListForJoin = ListForJoin
        MyAccount.UserName = UserName
        MyAccount.ID = ID
        let ResConnection
        let JoinTimeout;
        SetAutoJoinDB(ID)

        const JoinPromise1 = new Promise((resolve, reject) => {
            resolve(CheckConnection(AccountAutoJoinIsTrue.Session, AccountAutoJoinIsTrue.id));
            clearTimeout(JoinTimeout)
        });
        const JoinPromise2 = new Promise((resolve, reject) => {
            JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
        });
        ResConnection = await Promise.race([JoinPromise1, JoinPromise2])
        // console.log(ResConnection)

        if (ResConnection.status == true || ResConnection.status == undefined && ResConnection.Client != false) {
            dispatch(setMiniLoading(false))

            // setAutoJoinStatus(true)
            try {
                // console.log('start')
                // let LimitTime = 60 * 1000
                // let FinalLimit = Date.now() + LimitTime
                // try {
                //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                // } catch (error) {
                //     console.log(error)
                // }
                MyAccount.Loop(ID, AccountAutoJoinIsTrue.length)
            } catch (error) {
                // console.log(error)

            }

        } else {
            if (ResConnection.status == "logout") {

            } else {
                handleClick({ vertical: 'bottom', horizontal: 'center' })
                dispatch(setMiniLoading(false))
                // console.log(ResConnection)
                let MyItem = await TelegramDB.Data.toArray()
                setTimeout(() => {
                    // console.log('Connection error after 200 ms')
                    // console.log(AccountAutoJoinIsTrue.AutoJoin)
                    MyItem.map(item => {
                        if (item.id == AccountAutoJoinIsTrue.id) {
                            if (item.AutoJoin == true) {
                                // console.log('set single3')

                                StartOneAccountJoin(AccountAutoJoinIsTrue)
                            }

                        }
                    })

                }, 1000);

            }
            CountBannedLogOut()
        }



    }
    const StartAllAccountJoin = async (Status) => {
        // console.log(typeof (Status))



        dispatch(setMiniLoading(true))
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        TelegramAccountUpdate.map(item => {
            SetAutoJoinDB(item.id)
        })






        for (let index = 0; index < TelegramAccountUpdate.length; index++) {
            let Session = TelegramAccountUpdate[index].Session
            let ListForJoin = TelegramAccountUpdate[index].ListForJoin
            let UserName = TelegramAccountUpdate[index].User.username
            let ID = TelegramAccountUpdate[index].id
            try {
                let res = await TelegramDB.Data.where({ id: Number(TelegramAccountUpdate[index].id) }).modify({ LiveCoinState: 0 })
            } catch (error) {
                // console.log(error)
            }
            const MyAccount = Object.create(AutoManualJoinObject);
            // const MyAccount = new AutoManualJoin(Session, ListForJoin, UserName, ID);
            // const MyAccount = new AutoManualJoin(Session, ListForJoin, UserName, ID);
            MyAccount.Session = Session
            MyAccount.ListForJoin = ListForJoin
            MyAccount.UserName = UserName
            MyAccount.ID = ID



            if (TelegramAccountUpdate[index].Session != false) {

                // localStorage.setItem(TelegramAccountUpdate[index].id + "LiveCoin", 0)
                let ResConnection
                let JoinTimeout;
                const JoinPromise1 = new Promise((resolve, reject) => {
                    // setTimeout(resolve, 2, CheckConnection(this.MySession, this.ID));
                    resolve(CheckConnection(TelegramAccountUpdate[index].Session, TelegramAccountUpdate[index].id));
                    clearTimeout(JoinTimeout)
                });


                const JoinPromise2 = new Promise((resolve, reject) => {
                    JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
                });
                // ResConnection1 = await CheckConnection(this.MySession, this.ID)
                ResConnection = await Promise.race([JoinPromise1, JoinPromise2])
                // console.log(ResConnection)
                if (ResConnection.status == true || ResConnection.status == undefined && ResConnection.Client != false) {
                    dispatch(setMiniLoading(false))




                    setAutoJoinStatus(true)
                    try {

                        // let LimitTime = 60 * 1000
                        // let FinalLimit = Date.now() + LimitTime
                        // try {
                        //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                        // } catch (error) {
                        //     console.log(error)
                        // }

                        MyAccount.Loop(ID, TelegramAccountUpdate.length)
                        // MyAccount.Loop(ID);
                    } catch (error) {
                        // console.log(error)

                    }

                } else {
                    handleClick({ vertical: 'bottom', horizontal: 'center' })
                    dispatch(setMiniLoading(false))
                    // console.log(ResConnection)

                    let MyItem = await TelegramDB.Data.toArray()
                    setTimeout(() => {
                        // console.log('Connection error after 200 ms')

                        MyItem.map(item => {
                            if (item.id == TelegramAccountUpdate[index].id) {
                                if (item.AutoJoin == true) {
                                    StartAllAccountJoin()
                                }

                            }
                        })
                        // let TelegramAccountUpdate = await TelegramDB.Data.toArray()

                        if (TelegramAccountUpdate[index].AutoJoin == true) {
                            // StartAllAccountJoin()
                        }

                    }, 5000);
                }


                CheckAccounts()



            }
        }



    }

    const StopAllAccountJoin = async () => {
        // console.log("stop")
        setAutoJoinStatus(false)
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        for (let index = 0; index < TelegramAccountUpdate.length; index++) {
            localStorage.setItem(TelegramAccountUpdate[index].id + "LiveCoin", 0)

            if (TelegramAccount[index].Session != false) {
                dispatch(setAutoJoinLocal({ id: TelegramAccount[index].id, Autojoin: false }))


                try {
                    let res = await TelegramDB.Data.where({ id: Number(TelegramAccountUpdate[index].id) }).modify({ AutoJoin: false })
                    let res1 = await TelegramDB.Data.where({ id: Number(TelegramAccountUpdate[index].id) }).modify({ AutoJoinSingle: false })
                    // console.log(res)
                    if (res) {
                        CheckAccounts()
                        setAutoJoinStatus(false)
                    }
                } catch (error) {
                    // console.log(`Failed to add ${'Session'}: ${error}`);
                }
            }
            // let res = await AutoJoi nClass(TelegramAccount[index])
            // console.log(res)
        }
        // CountActiveAcounts()

        // console.log('end stop')
    }
    const GetListFromApi = async (BotSession, id, Session) => {


        if (BotSession) {

            // let res = await GetChannels(Token, BotSession)
            const MakeApiClass = new AllApi();
            let res = await MakeApiClass.GetChannels(Token, BotSession)
            // console.log(res)
            if (res.data.limit == 'limit') {
                // setTooMuchLeft({ status: true, id: id })
                // dispatch(SetTooMuchLeft({ status: true, id: id }))
                try {
                    TelegramDB.Data.where({ id: Number(id) }).modify({ TooMuchLeft: true })
                } catch (error) {
                    // console.log(`Failed to add ${'Session'}: ${error}`);
                }


                return false
            } else {
                if (res.code == 200) {
                    // console.log(res)
                    AddTelegramSession(res.data.data, id)
                    return true
                } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                    let name = "Token"
                    let name1 = "active"
                    document.cookie = name + '=; Max-Age=-99999999;';
                    document.cookie = name1 + '=; Max-Age=-99999999;';
                    history.push("/");
                    // dispatch(setLoading(false))

                }
            }

        }
    }
    const AddTelegramSession = async (List, ID) => {
        try {
            TelegramDB.Data.where({ id: Number(ID) }).modify({ ListForJoin: List })
        } catch (error) {
            // console.log(`Failed to add ${'Session'}: ${error}`);
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarLogout(false);
    };


    const CheckFloodWait = async (Accountlimit) => {
        let item;

        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
        TelegramAccountUpdatelimit.map(item1 => {
            if (item1.id === Number(Accountlimit.id)) {
                // console.log(item1)
                item = item1
            }

        })
        let Now = Date.now()
        let Status = null


        // console.log(Accountlimit)
        if (item) {
            if (item.FloodWait) {
                if (item.FloodWait != null) {
                    // console.log(Now)
                    // console.log(item.FloodWait)
                    if (Now < item.FloodWait) {
                        let LimitTimeMiliSecond = item.FloodWait - Now
                        LimitTimeMiliSecond = LimitTimeMiliSecond / 1000
                        // console.log(LimitTimeMiliSecond)
                        // console.log(item.FloodWait)
                        setFloodWaitState(true)
                        Status = true
                        let res = await timer(LimitTimeMiliSecond, item.id, item)
                        // console.log(res)
                        if (res == true) {
                            CheckFloodWait(item)
                            setFloodWaitState(false)
                            try {
                                let res = await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWait: 0 })

                            } catch (error) {
                                // console.log(error)
                            }

                        }
                    } else {
                        setFloodWaitState(false)
                        Status = false

                        try {
                            let res = await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWait: 0 })

                        } catch (error) {
                            // console.log(error)
                        }
                        // console.log('set single454 in flood')
                        // console.log(item.AutoJoin, item.AutoJoinSingle)
                        if (item.AutoJoin == true || item.AutoJoinSingle) {
                            StartOneAccountJoin(item)
                        }


                    }
                } else {
                    // console.log(1147)
                    if (item.AutoJoin == true || item.AutoJoinSingle) {
                        StartOneAccountJoin(item)
                    }
                    Status = false
                }
            } else {
                if (item.AutoJoin == true || item.AutoJoinSingle) {
                    StartOneAccountJoin(item)
                }
                Status = false
            }
        }
    }
    // let timer2
    const sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const timer = async (limit, id, item) => {
        let status = false;

        for (let i = 0; i <= limit; i++) {
            // console.log(i, limit)
            let TelegramAccountUpdate = await TelegramDB.Data.toArray()
            let Account
            TelegramAccountUpdate.map(item => {
                if (item.id == id) {
                    Account = item
                }
            })

            if (Account.AutoJoin == true) {
                await sleep(1000);
                // console.log(`Waiting ${i} Limit...`);

                status = true
            } else {
                status = false
                break
            }


        }
        // console.log('call check')
        // CheckFloodWait(item)

        return status
    }


    let timer3
    const timer33 = async (limit, id, item) => {
        var sec = limit;
        window.timer3 = setInterval(async function () {
            // console.log(sec)
            clearInterval(window.timer3)

            sec--;
            if (sec < 0) {
                clearInterval(window.timer3)

                // console.log('end')

                setFloodWaitState(false)
                try {
                    let res = TelegramDB.Data.where({ id: Number(id) }).modify({ FloodWait: 0 })

                } catch (error) {
                    // console.log(error)
                }
                // console.log(item)
                // console.log('emoty')
                // let res = await CheckLimit(item)
                // console.log(res)
                // StartOneAccountJoin(item)

                CheckAccounts()
            } else {
                // console.log(sec)
                // secondsToHms(sec)
                setFloodWaitState(true)
                // try {
                //     let res = await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWaitSecond: Math.floor(sec) })

                // } catch (error) {
                //     console.log(error)
                // }
                return sec
            }

        }, 1000);
        //   let res = await CheckLimit(item)
        //             console.log(res)
    }
    const CheckLimit = async (Accountlimit) => {

        let Now = Date.now()
        let Status = null
        let item;

        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
        TelegramAccountUpdatelimit.map(item1 => {
            if (Accountlimit.id === item1.id) {
                // console.log(item)
                item = item1
            }

        })
        //   console.log(item)

        if (item) {
            if (item.FloodWait) {
                if (item.FloodWait != null) {
                    // console.log(Now)
                    // console.log(item.FloodWait)
                    if (Now < item.FloodWait) {
                        let LimitTimeMiliSecond = item.FloodWait - Now
                        LimitTimeMiliSecond = LimitTimeMiliSecond / 1000
                        // console.log(LimitTimeMiliSecond)
                        // console.log(item.FloodWait)

                        // timer3(LimitTimeMiliSecond, item.id, item)
                        setFloodWaitState(true)
                        Status = true
                    } else {
                        setFloodWaitState(false)
                        Status = false

                        try {
                            let res = await TelegramDB.Data.where({ id: Number(item.id) }).modify({ FloodWait: 0 })

                        } catch (error) {
                            // console.log(error)
                        }
                        // console.log('set single454')
                        CheckFloodWait(Accountlimit)

                        // StartOneAccountJoin(item)
                        // CheckFloodWait(Accountlimit)

                        CheckAccounts()

                    }
                } else {
                    // console.log(1147)
                    Status = false
                }
            } else {
                // console.log(1151)
                Status = false
            }
        }


        return Status
    }
    const CheckStatus = async (item) => {
        if (item.Session && item.BotSession) {
            let ResConnection

            const delayTime = (time) =>
                setTimeout(() => {
                    // console.log("run after:", time);
                    try {
                        if (ResConnection.status == true && ResConnection.Client != false) {

                        } else {
                            handleClick({ vertical: 'bottom', horizontal: 'center' })
                            dispatch(setMiniLoading(false))
                        }
                    } catch (error) {
                        // clearTimeout(delayTime);

                        handleClick({ vertical: 'bottom', horizontal: 'center' })
                        dispatch(setMiniLoading(false))
                    }

                    CountBannedLogOut()
                }, time);

            const runTime = async () => {
                await delayTime(6000);
            };

            runTime();
            ResConnection = await CheckConnection(item.Session, item.id)
        }

    }
    const Logout = async (id, phone) => {
        setPhoneForLogout(phone)
        setIdForLogout(id)
        setShowLogOut(true)
    }
    const ConfirmLogOut = async (id) => {
        // console.log(id)
        localStorage.setItem('Banned', JSON.stringify(['']))

        setLoadingForLogOut(true)
        try {
            const id1 = await TelegramDB.Data.delete(id);
            // console.log(id1)
        } catch (error) {
            // console.log(`Failed to delete ${id}: ${error}`);
        }
        setLoadingForLogOut(false)
        setShowLogOut(false)
        CountBannedLogOut()
    }
    function onDismiss() {
        setTimeout(() => {
            setShowLogOut(false)
        }, 300);
    }
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose1 = () => {
        setState({ ...state, opens: false });
    };

    // const [Banned, setBanned] = useState('')
    // let Banned;
    const [Banny, setBanny] = useState()
    const CountBannedLogOut = async () => {

        let arr = []
        let res = await GetUpdatedTelegramAccont()

        let testarr = [1]
        localStorage.setItem('Banned', JSON.stringify(testarr))


        if (res.length > 0) {

            res.map((item, i) => {

                if (item.Session == false) {
                    let BannedFromLocal = JSON.parse(localStorage.getItem('Banned'))
                    // console.log(BannedFromLocal)
                    if (BannedFromLocal != null || BannedFromLocal != 'null') {
                        if (BannedFromLocal.length > 0) {
                            BannedFromLocal.map(banned => {
                                if (banned.id != item.id) {

                                    arr.push(item)

                                }
                            })
                        } else {
                            arr.push(item)
                        }
                    } else {
                        arr.push(item)
                    }
                }
            }
            )
        }

        let uniqueArray = arr.filter(function (item, pos) {
            return arr.indexOf(item) == pos;
        })


        localStorage.setItem('Banned', JSON.stringify(uniqueArray))


        setBanny(uniqueArray)
        CheckAccounts()
    }
    // console.log(Banned)
    let Banned = JSON.parse(localStorage.getItem('Banned'))

    const deepEqual = (arr1, arr2) => {
        if (arr1 === arr2) return true; // Handle same object case

        if (arr1.length !== arr2.length) return false; // Different lengths

        for (let i = 0; i < arr1.length; i++) {
            // Check if element types are the same (primitive vs. object)
            if (typeof arr1[i] !== typeof arr2[i]) return false;

            // If primitive types, compare directly
            if (typeof arr1[i] === 'primitive') {
                if (arr1[i] !== arr2[i]) return false;
                continue;
            }

            // If objects, perform recursive deep comparison
            if (!deepEqual(arr1[i], arr2[i])) return false;
        }

        return true; // All elements match
    }

    useEffect(() => {
        // console.log(Banned, Banny)
        if (Banny == undefined) {
            // console.log(0)
            CountBannedLogOut()

        } else {
            // console.log(deepEqual(Banned, Banny))
            if (deepEqual(Banned, Banny)) {
                // console.log(1)

            } else {
                // console.log(2)
                CountBannedLogOut()

            }
        }

        Banned = JSON.parse(localStorage.getItem('Banned'))
    }, [Banned])
    useEffect(() => {


        setLoadingState(true)
        CheckAccounts()
        setTimeout(() => {
            setLoadingState(false)

        }, 1000);
        clearInterval(timer1use)
        clearInterval(window.timer2)
        timeruse()
        checkLiveCoin()

    }, [])

    const checkLiveCoin = async () => {
        let res = await GetUpdatedTelegramAccont()
        // console.log(res)
        if (res.length > 0) {
            res.map((item, i) => {
                let LiveCoinVar = localStorage.getItem(item.id + "LiveCoin")
                if (LiveCoinVar == 'null' || LiveCoinVar == undefined) {
                    localStorage.setItem(item.id + "LiveCoin", 0)
                }
            }
            )
        }

    }
    let timer1use
    const timeruse = async () => {
        let res = await GetUpdatedTelegramAccont()
        // console.log(res)
        timer1use = setInterval(function () {

            if (res.length > 0) {

                res.map((item, i) => {

                    CheckLimit(item)
                    if (item.AutoJoin == true) {
                        // clearInterval(window.timer1)
                    }


                }
                )
            }
            CountBannedLogOut()
            CheckAccounts()

        }, 10000);

    }
    // console.log(CountJoiningLoading)
    const [Sticker, setSticker] = useState('')
    return (
        <>
            {
                LoadingState ?
                    <Loading />
                    :

                    <div className='AccountList1'>

                        <Snackbar
                            open={SnackbarLogout}
                            autoHideDuration={2000}
                            onClose={handleClose}
                            message="Something went wrong! Please try again."

                        />
                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={opens}
                            onClose={handleClose1}
                            // message={` Password changed`}
                            // key={vertical + horizontal}
                            autoHideDuration={2000}
                            message={<span id="client-snackbar">
                                <WifiOffIcon className='WifiIcon' /> No internet connection</span>
                            }

                        />
                        <div className='AccountListContainer'>
                            {/* CountJoiningLoading ? */}
                            {/* <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' /> */}
                            {/* <GameCoin /> */}

                            {
                                AccountCount > 0 ?
                                    <div className='AccountListHeader'  >
                                        <div className='AccountListHeaderItem1'>
                                            <span className='AccountListHeaderItem1Inner'>
                                                Accounts
                                            </span>


                                        </div>

                                        {/* {AutoJoinStatus == true || CountJoining > 0 ?
                                            <div className='AccountListHeaderItem2' style={{ 'color': '#D32F2F' }} onClick={() => StopAllAccountJoin()}>Stop all</div>
                                            :
                                            ShowStartAll ?
                                                <div className='AccountListHeaderItem2' onClick={() => MiniLoading ? null : StartAllAccountJoin('All')}>
                                                    {
                                                        MiniLoading ?
                                                            <div className="loadminiBlue"></div>
                                                            :
                                                            ' Start all '


                                                    }

                                                </div> : null

                                        }    */}
                                        <div className='AccountListHeaderItem1'>
                                            <span className='AccountListHeaderItem1Inner'>
                                                {CountJoiningLoading ? <Skeleton width={55} height={24} style={{ 'marginLeft': '4px', 'borderRadius': '8px' }} /> 
                                                : 
                                                // ' ( ' + CountJoining + ' / ' + AccountCount + ' ) '
                                                CountJoining 
                                                
                                                }
                                            </span>

                                            &nbsp;Joining accounts </div>
                                    </div>
                                    :
                                    null
                            }





                            {
                                TelegramAccount ?
                                    TelegramAccount.length > 0 ?
                                        TelegramAccount.map((item, i) =>

                                            item.Session != false ?


                                                item.BotSession != '' ?
                                                    // CheckLimit(item)
                                                    // &&
                                                    <>
                                                        <Link to={`/AutoManualJoin?id=${item.id}`} key={i + 'link'}
                                                            className='AccountListItemActive' >
                                                            <div className="AccountListItemActiveItem1" >
                                                                <div className='AccountListItemActiveImageContainer'>
                                                                    {
                                                                        // item.FloodWait == 0 || item.FloodWait == undefined ?
                                                                        item.CHANNELS_TOO_MUCH == false || item.CHANNELS_TOO_MUCH == undefined ?
                                                                            item.TooMuchLeft == false || item.TooMuchLeft == undefined ?
                                                                                item.AutoJoin || item.AutoJoinSingle ?
                                                                                    <div className="AccountListItemActiveImageJoiningLoader">

                                                                                    </div>
                                                                                    : null
                                                                                : null
                                                                            : null
                                                                        // : null
                                                                    }

                                                                    {item.ProfilePhoto ?
                                                                        item.ProfilePhoto == "data:text/plain;base64," ?
                                                                            <div className='ProfilePhotoTextContainer'>
                                                                                <span className='ProfilePhotoText' style={
                                                                                    // (item.FloodWait == 0 || item.FloodWait == undefined) && 
                                                                                    item.CHANNELS_TOO_MUCH != true && item.TooMuchLeft != true
                                                                                        && (item.AutoJoin == true || item.AutoJoinSingle) ?

                                                                                        { "width": "32px", "height": "32px" }
                                                                                        :
                                                                                        { "width": "44px", "height": "44px" }
                                                                                    // { "width": "44px", "height": "44px" }
                                                                                    // :

                                                                                }
                                                                                >
                                                                                    {item.User.firstName.charAt(0).toUpperCase()}
                                                                                </span>
                                                                            </div>
                                                                            // ||item.CHANNELS_TOO_MUCH==false
                                                                            :
                                                                            <img src={item.ProfilePhoto} className={item.CHANNELS_TOO_MUCH != true && item.TooMuchLeft != true ? item.AutoJoin == true || item.AutoJoinSingle == true ? 'AccountListItemActiveImageJoining' : 'AccountListItemActiveImage' : 'AccountListItemActiveImage'} />
                                                                        :
                                                                        <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' />
                                                                    }
                                                                </div>
                                                                <div className='AccountListItemActiveNamePhone'>
                                                                    <div className='AccountListItemActiveName'>{item.User.firstName ? item.User.firstName : null}
                                                                        {item.FloodWait > 0 || (item.CHANNELS_TOO_MUCH == true) || (item.TooMuchLeft == true) ? <span className='RestrictSpan'>Restrict</span> : null}

                                                                    </div>
                                                                    <div className='AccountListItemActivePhone'>
                                                                        {
                                                                            item.CountryName ?
                                                                                < ReactCountryFlag
                                                                                    // key={j}
                                                                                    countryCode={item.CountryName}
                                                                                    className='FlagPhoneNumber'
                                                                                    svg
                                                                                    title={item.CountryName}
                                                                                />

                                                                                : null
                                                                        }

                                                                        {
                                                                            // formatPhoneNumber('+12345678900')
                                                                            // formatPhoneNumber(
                                                                            '+' + item.PhoneNumber
                                                                            // )
                                                                        }</div>
                                                                </div>
                                                            </div>
                                                            <div>

                                                                {/* {
                                                                    'wait' + '='+
                                                                
                                                                    item.FloodWaitSecond
                                                                } */}


                                                            </div>
                                                            <div>

                                                                {
                                                                    // 'coin:' +
                                                                    // localStorage.getItem(item.id + "LiveCoin")
                                                                }
                                                            </div>
                                                            <div className='AccountListItemActiveItem2' >
                                                                <ArrowForwardIosIcon className='AccountListItemArrowRight' />
                                                            </div>
                                                        </Link>

                                                    </>




                                                    : null
                                                : null
                                        )
                                        :
                                        null
                                    : null

                            }
                            <div className='AccountListHeader'>
                                {
                                    Banned ?
                                        Banned.length > 0 ?

                                            <div className='AccountListHeaderItem1' >Banned & logged out</div>
                                            : null
                                        : null

                                }


                            </div>
                            {
                                //    let Banned=Banned 

                                Banned ?
                                    Banned.length > 0 ?
                                        Banned.map((item, i) =>
                                            item.Session == false ?
                                                <div className='AccountListItemDEActive' key={i + item.id}>

                                                    <div className="AccountListItemActiveItem1"  >
                                                        <div className='AccountListItemActiveImageContainer' >
                                                            {item.ProfilePhoto ?
                                                                item.ProfilePhoto == "data:text/plain;base64," ?
                                                                    <div className='ProfilePhotoTextContainer'>
                                                                        <span className='ProfilePhotoText' style={{ "width": "44px", "height": "44px", "opacity": "0.6" }}>
                                                                            {item.User.firstName.charAt(0).toUpperCase()}
                                                                        </span>
                                                                    </div>

                                                                    :
                                                                    <img src={item.ProfilePhoto} className={'AccountListItemActiveImage'} style={{ "opacity": "0.6" }} />
                                                                :
                                                                <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' style={{ "opacity": "0.6" }} />
                                                            }
                                                            {/* {item.ProfilePhoto ?
                                                                <img src={item.ProfilePhoto} className='AccountListItemActiveImage' />
                                                                :
                                                                <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' />

                                                            } */}

                                                        </div>
                                                        <div className='AccountListItemActiveNamePhone'>
                                                            <div className='AccountListItemActiveName' style={{ "opacity": "0.6" }}>{item.User.firstName ? item.User.firstName : null}
                                                                {/* <span className='RestrictSpan'>Restrict</span> */}
                                                            </div>
                                                            <div className='AccountListItemActivePhone' style={{ "opacity": "0.6" }}>
                                                                {
                                                                    item.CountryName ?

                                                                        < ReactCountryFlag

                                                                            countryCode={item.CountryName}
                                                                            className='FlagPhoneNumber'
                                                                            svg
                                                                            title={item.CountryName}
                                                                        />


                                                                        : null
                                                                }
                                                                +{item.PhoneNumber}</div>
                                                        </div>
                                                    </div>

                                                    <div className='AccountListItemActiveItem2'>
                                                        <button className='AccountListItemRemove' onClick={() => Logout(item.id, item.PhoneNumber)} >
                                                            Delete
                                                        </button>

                                                    </div>
                                                </div>
                                                : null
                                        )
                                        : null
                                    // <div className="loadminiBlue"></div>
                                    : null

                            }
                        </div>
                        {/* <div className='AddAccountAbsoluteButton'>
                            <div className='AddAccountAbsoluteButtonInnerContainer'> */}


                        <div className='AddAccountAndAutoJoinInList'>
                            <div className='AddAccountAndAutoJoinInListInner'>
                                <Link to='/LoginTelegram' className='AddAccoontButton2'>
                                    <img className="plus15" src={plus15} />
                                    <img src={AddAccount2} /> Add Account
                                </Link>
                                <div className='AutoJoinListButton2Container'>
                                    {AutoJoinStatus == true || CountJoining > 0 ?
                                        <div className='AccountListHeaderItem2'>
                                            <div className='StopListButton2' onClick={() => StopAllAccountJoin()}>
                                                <img src={JoinStop} className='JoinStopIcon' />     Stop
                                            </div>
                                        </div>

                                        :
                                        ShowStartAll ?
                                            <div className='AccountListHeaderItem2' onClick={() => MiniLoading ? null : StartAllAccountJoin('All')}>
                                                {
                                                    // MiniLoading ?
                                                    //     <div className="loadminiBlue"></div>
                                                    //     :
                                                        <button className='AutoJoinListButton2'>
                                                            <img src={AutoJoinList} /> AUTO JOIN
                                                        </button>


                                                }

                                            </div>
                                            : null

                                    }

                                </div>

                            </div>

                        </div>

                        {/* <Link to='/LoginTelegram' className='AddAccountAbsoluteButtonInner'>
                            <AddIcon className='AddIcon' />
                        </Link> */}

                        {/* </div>

                        </div> */}
                        <BottomSheet onDismiss={onDismiss} open={ShowLogOut}>
                            <div className='RegisterBeforeBottomSheet'>
                                <div className='RegisterBeforeBottomSheetHeader'>
                                    <div className='RegisterBeforeBottomSheetHeaderText1'>
                                        {/* <img src={DangerBottomSheet} className='DangerBottomSheet' /> */}
                                        Remove from the list
                                    </div>
                                </div>
                                <div className='RegisterBeforeBottomSheetBody'>

                                    Are you sure you want to remove <span className='UserNameChannelBold'>+{PhoneForLogout}</span> from the list?


                                </div>
                                <div className='RegisterBeforeBottomSheetFooter'>
                                    <button className='BottomSheetCancelButton' onClick={() => setShowLogOut(false)}>Cancel</button>
                                    <button className='BottomSheetLoginButton' onClick={() => ConfirmLogOut(IdForLogout)}>
                                        {
                                            LoadingForLogOut ? <div className="loadminijoin"></div>
                                                : "Remove"
                                        }

                                    </button>
                                </div>
                            </div>
                        </BottomSheet>


                    </div>


            }
        </>
    )
}

export default AccountList